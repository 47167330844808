import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import classNames from "classnames/bind";
import BigNumber from "bignumber.js";
import Modal from "src/components/modal/Modal";
import styles from "./styles.scss";
import useAffiliateScript from "src/core/affiliate/hooks/useAffiliateScript";
import { axiosInstance } from "src/core/http/axios-instance";
import useAppStore from "src/useAppStore";
import useSettings from "src/components/game/hooks/useSettings";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import { IBetData } from "src/components/game/types/bet";

export interface ISendDepositPayload {
    asset: string;
    amount: string;
}

export interface ISendBetPayload {
    receiver: string;
    amount: string;
    data: string;
    asset: string;
}

const cx = classNames.bind(styles);

export interface ISendDepositOrBetItem {
    asset: string;
    fee: number;
    txid: string;
}

export interface ISendDepositOrBetResponse {
    data: ISendDepositOrBetItem
}

async function sendDeposit(amount: string) {
    const { data: transactionData } = await axiosInstance.post<ISendDepositOrBetResponse, ISendDepositPayload>(
        "/v1/deposit/deposit",
        {
            asset: "BSV",
            amount,
        },
        { timeout: 1000 * 60 },
    );
    return { transactionData, amount };
}

async function sendBet(receiver: string, amount: string, data: string, asset: string) {
    const { data: transactionData } = await axiosInstance.post<ISendDepositOrBetResponse, ISendBetPayload>(
        "/v1/wallets/send",
        {
            asset,
            receiver,
            amount,
            data,
        },
        { timeout: 1000 * 60 },
    );
    return { transactionData, amount };
}

async function getTopupUri(): Promise<string> {
    const { data: { uri } } = await axiosInstance.get<{ timestamp: number; data: { uri: string; }; }>("/v1/wallets/topup");

    return uri;
}

interface IUseBetProps {
    bettingData: IBetData;
    onBetSuccess: (transactionData: ISendDepositOrBetItem) => void;
    isDeposit: boolean;
}

export default function useBet({ bettingData, onBetSuccess, isDeposit }: IUseBetProps) {
    const selectedAsset = useAppStore(state => state.asset.selected);
    const affiliateScript = useAffiliateScript();
    const bettingScript = `${bettingData.script.replace("BSV", selectedAsset)}${affiliateScript}`;
    const betAmount = bettingData.multiplier
        ? bettingData.amount.multipliedBy(bettingData.multiplier).toFixed()
        : bettingData.amount.toFixed();
    const [lowBalance, setLowBalance] = useState(false);
    const [spendLimit, setSpendLimit] = useState(false);
    const [lowPgpBalance, setLowPgpBalance] = useState(false);
    const { isLoading: settingsLoading, address: receiver } = useSettings({ enabled: !isDeposit });

    const { mutate: placeBet, isPending: isSendingBet, error } = useMutation(
        {
            mutationFn: isDeposit ?
                () => sendDeposit(betAmount)
                :
                () => {
                    return sendBet(receiver, betAmount, bettingScript, selectedAsset)
                },
            onSuccess: ({ transactionData, amount }) => {
                const { asset } = transactionData;
                const paymentAmount = new BigNumber(amount);

                if (asset === "PGP") {
                    useAppStore.getState().setAssetBalance(currentBalance => ({
                        PGP: currentBalance.PGP.minus(paymentAmount),
                    }));
                }
                if (isDeposit) {
                    useGameStore.getState().setBettingDisabled(false);
                }
                onBetSuccess(transactionData);
            },
            onError: (err: any) => {
                useGameStore.getState().setBettingDisabled(false);
                if (err.code === "__low_balance__") {
                    if (selectedAsset === "PGP") {
                        setLowPgpBalance(true);
                    } else {
                        setLowBalance(true);
                    }
                } else if (err.code === "__spend_limit__") {
                    setSpendLimit(true);
                }
            },
        },
    );
    const { data: topupUri } = useQuery({
        queryKey: ["topup"],
        queryFn: getTopupUri,
        retry: false,
        enabled: !!error && (error.code === "__low_balance__" || error.code === "__spend_limit__"),
    });
    const isLoading = settingsLoading || isSendingBet;
    const errorModals = (
        <Fragment>
            <Modal isOpen={lowBalance} header="Low Balance" onClose={() => setLowBalance(false)}>
                <div className={cx("modal-body")}>
                    <p>Your balance is too low to make this payment</p>
                    <a href={topupUri} target="_blank" rel="noreferrer">
                        Add Balance
                    </a>
                </div>
            </Modal>
            <Modal isOpen={spendLimit} header="Spend Limit" onClose={() => setSpendLimit(false)}>
                <div className={cx("modal-body")}>
                    <p>Your wallet spend limit is too low to make this payment. Please top up and try again.</p>
                    <a href={topupUri} target="_blank" rel="noreferrer">
                        Adjust Spend Limit
                    </a>
                </div>
            </Modal>
            <Modal isOpen={lowPgpBalance} header="Low balance" onClose={() => setLowPgpBalance(false)}>
                <div className={cx("modal-body")}>
                    <p>Your Peergame Points balance is too low to make this payment</p>
                </div>
            </Modal>
        </Fragment>
    );

    return {
        placeBet,
        isLoading,
        error,
        errorModals,
    };
}
