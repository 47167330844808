import { TableCell, TableRow, TableHead, TableBody, TableHeader, Table } from "@pg/design-system";
import { cn } from "@ntropy/utils/src/cn";
import useGame from "src/components/game/hooks/useGame";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import { games } from "src/games/config";
import { StatsCategory } from "src/containers/Landing/components/statistics-section/types/statisticSections";
import { AvatarAndText } from "src/components/stats-table/components/avatar-and-text/AvatarAndText";
import { StatsTableSkeletonRow } from "src/components/stats-table/components/stats-table-skeleton-row/StatsTableSkeletonRow";
import { hideStyles } from "src/components/stats-table/assets/styles";

const tableSkeletonsLength = 10;

const tableColumns = [
    { label: "Game", hideMobile: false },
    { label: "User", hideMobile: false },
    { label: "Bet", hideMobile: true },
    { label: "Multiplier", hideMobile: true },
    { label: "Pick", hideMobile: true },
    { label: "Result", hideMobile: true },
    { label: "Payout", hideMobile: false },
]

interface IStatsTableProps {
    category: StatsCategory;
}

export const StatsTable = ({ category }: IStatsTableProps) => {
    const allBets = useGameStore(state => state.allBets);
    const highRollers = useGameStore(state => state.highRollers);

    const selectedBets = category === StatsCategory.AllBets ? allBets : highRollers;

    const shouldDisplayLoader = !selectedBets.length;

    useGame()

    const tableSkeletons = Array.from({ length: tableSkeletonsLength }).map((_, index) => <StatsTableSkeletonRow key={index} />)

    return (
        <Table>
            <TableHeader className="[&_tr]:border-none">
                <TableRow className="border-none">
                    {tableColumns.map(({ label, hideMobile }, index) => {
                        const isFirst = index === 0;
                        const isLast = index === tableColumns.length - 1;
                        const isBetHeader = label === "Bet";

                        return (
                            <TableHead
                                key={label}
                                className={cn("text-center", {
                                    "pl-4 md:pl-10 text-left": isFirst,
                                    "text-right flex justify-end pr-4 md:pr-10": isLast,
                                    "text-right": isBetHeader,
                                }, hideMobile && hideStyles)}
                            >
                                {label}
                            </TableHead>
                        )
                    })}
                </TableRow>
            </TableHeader>
            <TableBody>
                {shouldDisplayLoader ? tableSkeletons : selectedBets.map(
                    (({ game, profile_image: profileImage, amount, nickname, asset, winner, multiplier, area, game_result: result, payout, txid }, index) => {
                            const formattedMultiplier = `${Number(multiplier)?.toFixed(2)}x`;
                            const formattedArea = games[game].formatPick?.(area);
                            const formattedResult = games[game].formatResult?.(result);

                            return shouldDisplayLoader ?
                                <StatsTableSkeletonRow key={txid ?? index} />
                                : <>
                                    <TableRow key={txid ?? index} className="border-none text-xs">
                                        <TableCell className="pl-4 md:pl-10">
                                            <AvatarAndText icon={games[game]?.smallIcon} content={game} />
                                        </TableCell>
                                        <TableCell>
                                            <AvatarAndText icon={profileImage || ""} content={nickname || ""} className="justify-center" />
                                        </TableCell>
                                        <TableCell className={cn(hideStyles, "justify-end")}>
                                            <AvatarAndText icon={asset} content={amount} iconPlacement="right" />
                                        </TableCell>
                                        <TableCell className={cn(hideStyles, "justify-center")}>{winner ? formattedMultiplier : "0.00x"}</TableCell>
                                        <TableCell className={cn(hideStyles, "justify-center")}>{formattedArea}</TableCell>
                                        <TableCell className={cn(hideStyles, "justify-center")}>{formattedResult}</TableCell>
                                        <TableCell className="pr-4 md:pr-10">
                                            <AvatarAndText
                                                icon={asset}
                                                content={winner ? payout : `-${amount}`}
                                                iconPlacement="right"
                                                textColor={winner ? "text-game-green-dark" : "text-game-red-light"}
                                                className="justify-end shrink-0"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </>
                        }
                    ))}
            </TableBody>
        </Table>
    )
}