import Landing from "src/containers/Landing/Landing";
import App from "src/containers/App/App";
import { Account, accountRoutes } from "src/containers/Account/Account";
import { Affiliate, affiliateRoutes } from "src/containers/Affiliate/Affiliate";
import { Path } from "src/core/paths/paths";
import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import { Games, gamesRoutes } from "src/containers/Games/Games";
import { StaticPage, staticPages } from "src/static/StaticPage";
import LazyPage from "src/core/router/components/LazyPage";

const routes: RouteObject[] = [
    {
        element: <App />,
        children: [
            {
                path: Path.Home,
                element: <Landing />,
            },
            {
                element: <StaticPage />,
                children: staticPages,
            },
            {
                element: <Account />,
                children: accountRoutes,
            },
            {
                element: <Affiliate />,
                children: affiliateRoutes,
            },
            {
                element: <Games />,
                children: gamesRoutes,
            },
            {
                path: Path.Races,
                element: <LazyPage loader={() => import("src/containers/Races/Races")} />,
            },
            // {
            //     path: Path.NotAvailable,
            //     element: <LazyPage loader={() => import("src/containers/NotAvailable/NotAvailable")} />,
            // },
            {
                path: "*",
                element: <Navigate replace to={Path.Home} />,
            },
        ],
    },
]

const router = createBrowserRouter(routes);

export default router;
